
import { Vue, Component, Prop } from "vue-property-decorator";
import { Getter, State } from "vuex-class";
import { RootState } from "../store";
import { ShortListingFragment as ShortListingFragmentType } from "../gql-typings/ShortListingFragment";
import { SetListingReactionVariables, SetListingReaction as SetListingReactionResponse } from "../gql-typings/SetListingReaction";
import { SetListingSubscriptionVariables, SetListingSubscription as SetListingSubscriptionResponse } from "../gql-typings/SetListingSubscription";
import { ActorEventType, ListingFeedType, ListingReaction, ListingStatus } from "../gql-typings/globalTypes";
import { ListingReactionAndSubscription_listingReactionAndSubscription_ResidentialListing as ListingReactionAndSubscriptionNode, ListingReactionAndSubscriptionVariables } from "@/gql-typings/ListingReactionAndSubscription";

import ShortListingFragment from "@/apollo/ShortListingFragment.graphql";
import ListingReactionAndSubscriptionQuery from "@/apollo/ListingReactionAndSubscriptionQuery.graphql";
import SetListingReactionMutation from "@/apollo/SetListingReactionMutation.graphql";
import SetListingSubscriptionMutation from "@/apollo/SetListingSubscriptionMutation.graphql";

import { NavigatorShare } from "../misc/interfaces";

@Component({
	filters: {
		fromLookup(value: string): string {
			value = value.replace(/_/g, " ");

			return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
		},
	},
})
export default class ShortListingMixin extends Vue {
	@Prop({type: Object, required: true})
	listing!: ShortListingFragmentType;

	@State("viewer") viewer!: RootState["viewer"] | null;
	@Getter("agent") sessionAgent!: RootState["assignedAgent"];

	@State("canShare") canShare!: boolean;
	@State("isMobile") isMobile!: boolean;

	useOptimisticResponse: boolean = false;

	get showStatusBanner() {
		const {listing: {status, mlsStatusText, feedType}} = this;

		const notableStatuses = [ListingStatus.CANCELLED, ListingStatus.CLOSED, ListingStatus.ACTIVE_UNDER_CONTRACT, ListingStatus.EXPIRED, ListingStatus.WITHDRAWN, ListingStatus.HOLD, ListingStatus.PENDING];

		return (
			status !== null &&
			notableStatuses.includes(status) &&
			(
				mlsStatusText !== null ||
				feedType === ListingFeedType.BROKER_LIST
			)
		);
	}

	get close_price(): number | null {
		return this.listing.closeSalePrice || this.listing.closeLeasePrice;
	}

	get list_price(): number | null {
		return this.listing.listSalePrice || this.listing.listLeasePrice;
	}

	get href(): string {
		const url = new URL(this.listing.url);

		return url.href.replace(url.origin, "");
	}

	saveToApolloCache() {
		const {listing} = this;

		if (this.$apollo && this.$apollo.provider) {
			const client = this.$apollo.provider.defaultClient;

			client.writeFragment({
				id: `listings/${listing.oid}`,
				fragmentName: "ShortListingFragment",
				fragment: ShortListingFragment,
				data: listing,
			});
		}
	}

	private getListingReactionAndSubscription() {
		const {listing} = this;
		let listingReactionAndSubscription;

		try {
			const listingReactionAndSubscriptionResult = this.$apollo.getClient().readQuery<
				{listingReactionAndSubscription: ListingReactionAndSubscriptionNode},
				ListingReactionAndSubscriptionVariables
			>({
				query: ListingReactionAndSubscriptionQuery,
				variables: {
					id: this.listing.id,
				},
			});

			listingReactionAndSubscription = listingReactionAndSubscriptionResult?.listingReactionAndSubscription || listing;
		} catch (e) {
			listingReactionAndSubscription = listing;
		}

		return listingReactionAndSubscription;
	}

	async reactToListing(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (!this.viewer) {
			if (window.confirm("You must log in to save listings. Would you like to log in?")) {
				this.$router.push({path: "/login", query: {redirect: this.href}});
			}

			return;
		}

		const {listing} = this;
		const listingReactionAndSubscription = this.getListingReactionAndSubscription();

		const goalReaction: ListingReaction | null = listingReactionAndSubscription.viewerReaction ? null : ListingReaction.LIKE;

		const variables: SetListingReactionVariables = {
			input: {
				listing: listing.id,
				reaction: goalReaction,
			},
		};

		(this as any).$matomo?.trackEvent("Listing", `React:${goalReaction}`, listing.oid);

		const optimisticResponse: SetListingReactionResponse & {__typename: string} = {
            __typename: "Mutation",
			setListingReaction: {
				__typename: "ListingReactionPayload",
				listing: {
					__typename: listing.__typename,
					id: listing.id,
					oid: listing.oid,
					viewerReaction: goalReaction,
					viewerHasSubscribed: goalReaction === ListingReaction.LIKE || listing.viewerHasSubscribed,
				},
			},
		};

		let actorEventType: ActorEventType | null = null;

		if (listingReactionAndSubscription.viewerReaction !== ListingReaction.LIKE && goalReaction === ListingReaction.LIKE) {
			actorEventType = ActorEventType.LISTING_LIKE
		} else if (listingReactionAndSubscription.viewerReaction === ListingReaction.LIKE && goalReaction === null) {
			actorEventType = ActorEventType.LISTING_UNLIKE;
		}

		await this.$apollo.mutate<SetListingReactionResponse>({
			mutation: SetListingReactionMutation,
			variables,
			optimisticResponse: this.useOptimisticResponse ? optimisticResponse : undefined,
		});

		if (actorEventType) {
			await this.$store.dispatch("recordNodeActorEvent", {
				type: actorEventType,
				resource_id: listing.id,
			});

			if (actorEventType === ActorEventType.LISTING_LIKE && !listingReactionAndSubscription.viewerHasSubscribed) {
				await this.$store.dispatch("recordNodeActorEvent", {
					type: ActorEventType.LISTING_SUBSCRIBE,
					resource_id: listing.id,
				});
			}
		}
	}

	async subscribeToListing(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (!this.viewer) {
			if (window.confirm("You must log in to subscribe to listing updates. Would you like to log in?")) {
				this.$router.push({path: "/login", query: {redirect: this.href}});
			}

			return;
		}

		const {listing} = this;
		const listingReactionAndSubscription = this.getListingReactionAndSubscription();

		const goal: boolean = !listingReactionAndSubscription.viewerHasSubscribed;

		const variables: SetListingSubscriptionVariables = {
			input: {
				listing: listing.id,
				subscribe: goal,
			},
		};

		(this as any).$matomo?.trackEvent("Listing", "Subscribe to Updates", listing.oid);

		const optimisticResponse: SetListingSubscriptionResponse & {__typename: string} = {
            __typename: "Mutation",
			setListingSubscription: {
				__typename: "ListingSubscriptionPayload",
				listing: {
					__typename: listing.__typename,
					id: listing.id,
					oid: listing.oid,
					viewerHasSubscribed: goal,
				},
			},
		};

		await this.$apollo.mutate<SetListingSubscriptionResponse>({
			mutation: SetListingSubscriptionMutation,
			variables,
			optimisticResponse: this.useOptimisticResponse ? optimisticResponse : undefined,
		});

		await this.$store.dispatch("recordNodeActorEvent", {
			type: goal ? ActorEventType.LISTING_SUBSCRIBE : ActorEventType.LISTING_UNSUBSCRIBE,
			resource_id: listing.id,
		});
	}

	async shareListing(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();

		const {listing, sessionAgent} = this;

		if ((navigator as any).share) {
			let title = listing.property? `${listing.property.streetAddressFormatted}. ` : "";
			title += `Presented by ${sessionAgent!.name}`;

			const url = this.href;

			try {
				await ((navigator as any).share as NavigatorShare)({title, url});
			} catch (e) {
				if (e instanceof Error && e.name === "AbortError") {
					// the user cancelled sharing
					(this as any).$matomo?.trackEvent("Listing", "Share:Cancel", listing.oid);
				} else {
					this.$sentry?.captureException(e);
				}

				return;
			}

			(this as any).$matomo?.trackEvent("Listing", "Share:Complete", listing.oid);

			await this.$store.dispatch("recordNodeActorEvent", {
				type: ActorEventType.LISTING_SHARE,
				resource_id: listing.id,
			});
		}
	}

	clickToCopyHref() {
		const {listing} = this;

		if (!listing) {
			return null;
		}

		const textArea = document.createElement("textarea");
		textArea.value = listing.url;

		// Avoid scrolling to bottom
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			document.execCommand("copy");
		} catch (err) {
			return;
		} finally {
			document.body.removeChild(textArea);
		}

		this.$store.dispatch("recordNodeActorEvent", {
			type: ActorEventType.LISTING_SHARE,
			resource_id: listing.id,
		});

		const timeout = setTimeout(() => {
			this.$modal.hide("dialog");
		}, 3000);

		this.$modal.show("dialog", {
			text: "Copied link to listing",
			buttons: [
				{
					title: "Okay",
					default: true,
				},
			],
			"before-close": () => {
				clearTimeout(timeout);
			},
		});
	}
}
