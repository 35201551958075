var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"saved-listing-search-option saved-listing-search",on:{"click":_vm.applySavedListingSearch}},[_c('v-dialog'),_vm._v(" "),_c('modal',{attrs:{"name":`more-menu_${_vm.savedListingSearch.id}`,"adaptive":true,"reset":true,"maxWidth":320,"width":"100%","height":"auto","scrollable":true}},[_c('div',{staticClass:"more-menu"},[_c('a',{attrs:{"href":"#0"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (() => {
					_vm.editSearchTitle()
					_vm.$modal.hide(`more-menu_${_vm.savedListingSearch.id}`)
				}).apply(null, arguments)}}},[_vm._v("Rename Search")]),_vm._v(" "),_c('a',{attrs:{"href":"#0"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (() => {
					_vm.$modal.hide(`more-menu_${_vm.savedListingSearch.id}`)
					_vm.deleteSavedListingSearch()
				}).apply(null, arguments)}}},[_vm._v("Delete Search")]),_vm._v(" "),_c('a',{staticClass:"cancel",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$modal.hide(`more-menu_${_vm.savedListingSearch.id}`)}}},[_vm._v("Cancel")])])]),_vm._v(" "),_c('saved-listing-search-map-thumbnail',{attrs:{"polyline":_vm.savedListingSearch.polyline,"width":80,"height":80}}),_vm._v(" "),_c('div',{staticClass:"info"},[_c('h2',[_vm._v(_vm._s(_vm.savedListingSearch.title))]),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('a',{staticClass:"action subscribe",class:[_vm.savedListingSearch.viewerHasSubscribed ? `active` : `inactive`],attrs:{"href":"#0","title":_vm.savedListingSearch.viewerHasSubscribed ? `Don't notify` : `Notify`},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleSubsciption.apply(null, arguments)}}},[_c('span',[_vm._v("Notify")])]),_vm._v(" "),_c('client-only',[(_vm.canShare && _vm.isMobile)?_c('a',{staticClass:"action share",attrs:{"href":_vm.href,"title":"Share this search"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.shareSearch.apply(null, arguments)}}},[_c('span',[_vm._v("Share")])]):_c('a',{staticClass:"action link",attrs:{"href":_vm.href,"title":"Copy link to this search"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clickToCopyHref.apply(null, arguments)}}},[_c('span',[_vm._v("Link")])])]),_vm._v(" "),_c('a',{staticClass:"action more",attrs:{"href":"#0","title":"More"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$modal.show(`more-menu_${_vm.savedListingSearch.id}`)}}},[_c('span',[_vm._v("More")])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }